<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Copper',
    metaInfo: { title: 'Copper Integration' },
    extends: View,
    mixins: [
      LoadSections([
        'copper-integration',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'copper-integration',
      },
    },
  }
</script>
